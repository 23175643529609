import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import DOMPurify from 'dompurify';
import './ArticlePage.css';
import HorizontalForm from '../components/HorizontalForm';

// Loader Component
const Loader = () => {
    return (
        <div className="fullscreen-loader">
            <div className="loader-content">
                <img src="/images/logo.png" alt="Logo" className="loader-logo" />
                <p className="loader-text">יאס-מיגן אלופים במיגון</p>
            </div>
        </div>
    );
};

const ArticlePage = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [categories, setCategories] = useState([]);
    const [settings, setSettings] = useState({});
    const [galleryImages, setGalleryImages] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    // Fetch Article Data
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`https://www.yesmigon.co.il/api/index.php?action=apparticle&identifier=${id}&lang=he`);
                const data = response.data.data;

                setArticle(data.article);
                setCategories(data.categories);
                setSettings(data.settings);

                if (data.article.title) {
                    document.title = data.article.title;
                }

                if (data.article.galleryImages) {
                    const parsedGalleryImages = JSON.parse(data.article.galleryImages);
                    const galleryImagesArray = parsedGalleryImages.map((imagePath) => ({
                        original: `http://www.yesmigon.co.il/${imagePath.replace(/^(\.\/|\\)/, '')}?aspect_ratio=15:10`,
                        thumbnail: `http://www.yesmigon.co.il/${imagePath.replace(/^(\.\/|\\)/, '')}?aspect_ratio=10:10`,
                        originalAlt: 'Gallery Image',
                        thumbnailAlt: 'Gallery Thumbnail',
                    }));
                    setGalleryImages(galleryImagesArray);
                    setShowGallery(true);
                } else {
                    setShowGallery(false);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchArticle();

        // Check if the screen width is mobile size
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile screen size threshold (you can adjust the value if needed)
        };

        checkIfMobile(); // Run on mount
        window.addEventListener('resize', checkIfMobile); // Add resize listener

        return () => {
            window.removeEventListener('resize', checkIfMobile); // Clean up event listener
        };
    }, [id]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://www.yesmigon.co.il/api/index.php?action=priceRequest',
                new URLSearchParams({
                    name: formData.name,
                    phone: formData.phone,
                    message: formData.message,
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            if (response.data.status === 200) {
                alert('הבקשה נשלחה בהצלחה!');
                setFormData({ name: '', phone: '', message: '' });
            } else {
                alert('אירעה שגיאה בעת שליחת הבקשה. אנא נסה שנית.');
            }
        } catch (error) {
            console.error('Error submitting price request:', error);
            alert('אירעה שגיאה בעת שליחת הבקשה.');
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!article) {
        return <div>Loading...</div>;
    }

    const sanitizedHTML = DOMPurify.sanitize(article.fData, {
        ALLOWED_TAGS: ['p', 'h3', 'h2', 'strong', 'ol', 'li', 'iframe', 'div', 'img', 'a'],
        ALLOWED_ATTR: ['src', 'allow', 'allowfullscreen', 'style', 'alt', 'title', 'width', 'height', 'class', 'href'],
    });

    // Modify image URL for mobile
    const backgroundImageUrl = isMobile
        ? `https://www.yesmigon.co.il/${article.picture}?aspect_ratio=628:852`
        : article.picture;

    return (
        <>
            <Helmet>
                <title>{article.title}</title>
                <meta name="description" content={article.title} />
                <meta name="keywords" content={article.title} />
            </Helmet>
            <Header categories={categories} />
            <div
                className="header-base parallax"
                style={{
                    backgroundImage: `url(${backgroundImageUrl})`,
                }}>
                <div className="overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-base text-right">
                                <h1>{article.title}</h1>
                                <p>
                                    <ol className="breadcrumb b white">
                                        <li><Link to="/">ראשי</Link></li>
                                        <li><Link to={`/category/${article.mCat}`}>{article.category}</Link></li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-empty">
                <div className="container" style={{ paddingTop: 30 }}>
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            {/* Render content directly */}
                            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />

                            {/* Add Price Request Form */}
                            

                            {/* Add the Image Gallery if there are multiple images */}
                            {showGallery && galleryImages.length > 0 && (
                                <div className="article-gallery">
                                    <h2 className="gallery-title">גלריה עבודות שביצענו</h2>
                                    <ImageGallery
                                        items={galleryImages}
                                        showFullscreenButton={true}
                                        showPlayButton={false}
                                        isRTL={true}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-md-4 col-sm-12 widget mobileHide">
                            {/* Only render categories if article ID is not 11 */}
                            <div className="price-request-form">
                                <h3>צרו קשר איתנו לקבלת הצעת מחיר ויעוץ חינמי</h3>
                                <form onSubmit={handleFormSubmit} className="custom-form">
                                    <div className="form-group">
                                        <label htmlFor="name" className="form-label">שם:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            className="form-control custom-input"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone" className="form-label">טלפון:</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleFormChange}
                                            className="form-control custom-input"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message" className="form-label">הודעה:</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleFormChange}
                                            className="form-control custom-input"
                                            rows="4"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary custom-submit-btn circle-button">שלח</button>
                                </form>
                            </div>
                            {article.ID !== 11 && (
                                <div className="custom-category-list">
                                    <h3>תחומי עיסוק יס-מיגון</h3>
                                    {categories.map((category) => (
                                        <Link key={category.ID} to={`/category/${category.ID}`} className="list-group-item">{category.Name}</Link>
                                    ))}
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
            <HorizontalForm />
            <Footer settings={settings} />
        </>
    );
};

export default ArticlePage;
